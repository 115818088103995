import {FedopsInteractions, FORMS_CHECKOUT_NAMESPACE, FORMS_TEMPLATE_IDS} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {FormsStoreProps} from '../../types/app.types';
import {FormKind, initFormController} from '@wix/form-viewer/controller';
import type {FormController} from '@wix/form-viewer/controller';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {isBillingInfoValid, isShippingDestinationValid} from '../../components/Checkout/Form/FormValidations';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {PaymentService} from '../services/PaymentService';
import {WithResultObserver} from '@wix/function-result-observation/dist/types/worker/withResultObserver.worker';
import {AddressWithContactModel} from '../models/AddressWithContact.model';

export type FormsStoreConfig = {
  flowAPI: ControllerFlowAPI;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  checkoutSettingsService: CheckoutSettingsService;
  paymentService: PaymentService;
  biService: BIService;
  updateFormsLoaded: () => void;
  onLoadFailure: () => Promise<void>;
  observe: WithResultObserver;
};

export class FormsStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly paymentService: PaymentService;
  private readonly navigationService: NavigationService;
  private readonly biService: BIService;
  private areFormsLoaded!: boolean;
  private readonly updateFormsLoaded: () => void;
  private readonly onLoadFailure: () => Promise<void>;
  private validateForm!: FormController['validateForm'];
  private readonly observe: WithResultObserver;

  constructor({
    flowAPI,
    navigationService,
    checkoutService,
    checkoutSettingsService,
    paymentService,
    observe,
    biService,
    updateFormsLoaded,
    onLoadFailure,
  }: FormsStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.navigationService = navigationService;
    this.onLoadFailure = onLoadFailure;
    this.updateFormsLoaded = updateFormsLoaded;
    this.areFormsLoaded = false;
    this.checkoutSettingsService = checkoutSettingsService;
    this.paymentService = paymentService;
    this.observe = observe;

    if (!this.navigationService.isFastFlow) {
      void this.initForms();
    }
  }

  private async initForms() {
    const requestedTemplates = [
      FORMS_TEMPLATE_IDS.EMAIL,
      FORMS_TEMPLATE_IDS.CONTACT,
      FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
      FORMS_TEMPLATE_IDS.ADDRESS,
      FORMS_TEMPLATE_IDS.VAT_ID,
    ];
    try {
      this.flowAPI.fedops.interactionStarted(FedopsInteractions.InitFormsInteraction);
      const {validateForm} = await initFormController(this.flowAPI, {
        formId: requestedTemplates,
        formKind: FormKind.EXTENSION,
        namespace: FORMS_CHECKOUT_NAMESPACE,
        enableMultilineAddress: true,
      });
      this.validateForm = validateForm;
      this.flowAPI.fedops.interactionEnded(FedopsInteractions.InitFormsInteraction);
    } catch (error) {
      this.flowAPI.errorMonitor.captureException(error as Error);
      this.biService.checkoutIntegratingFormsFailure(requestedTemplates, error, this.checkoutService.checkout);
      console.error('initFormController failed', error);
      await this.onLoadFailure();
      return;
    }
    this.areFormsLoaded = true;
    this.updateFormsLoaded();
  }

  private readonly validateBillingAddress = (addressWithContact: AddressWithContactModel): Promise<boolean> => {
    return isBillingInfoValid({
      validate: this.validateForm,
      addressWithContact,
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      context: this.paymentService.cashierMandatoryFields,
    });
  };

  private readonly validateShippingAddress = (addressWithContact: AddressWithContactModel): Promise<boolean> => {
    return isShippingDestinationValid({
      addressWithContact,
      validate: this.validateForm,
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      context: this.checkoutService.checkout?.customField,
    });
  };

  public toProps(): FormsStoreProps {
    return {
      areFormsLoaded: this.areFormsLoaded,
      ...this.observe({
        validateBillingAddress: this.validateBillingAddress,
        validateShippingAddress: this.validateShippingAddress,
      }),
    };
  }
}
