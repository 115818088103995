import {CashierMandatoryFieldsOverrides} from '../../../types/app.types';
import {COUNTRIES_WITH_VAT, FORMS_TEMPLATE_IDS} from '../constants';
import {getContactFormInitialState, getContactFormOverrides} from './ContactForm/contactForm.utils';
import {getAddressFormInitialState, getAddressFormOverrides} from './AddressForm/addressForm.utils';
import {getVatFormInitialState} from './VatIdForm/VatForm.utils';
import {
  getAdditionalInfoFormInitialState,
  getAdditionalInfoFormOverrides,
} from './AdditionalInfoForm/AdditionalInfoForm.utils';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import {CheckoutSettingsModel} from '../../../domain/models/checkoutSettings/CheckoutSettings.model';
import type {FormController} from '@wix/form-viewer/controller';

export type MemberDetailsValidator<S> = (options: {
  addressWithContact: AddressWithContactModel;
  checkoutSettings: CheckoutSettingsModel;
  context: S;
  validate: FormController['validateForm'];
}) => Promise<boolean>;

export const isBillingInfoValid: MemberDetailsValidator<CashierMandatoryFieldsOverrides> = async ({
  validate,
  addressWithContact,
  checkoutSettings,
  context: cashierMandatoryFields,
}) => {
  const contactValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.CONTACT,
      values: getContactFormInitialState({
        checkoutSettings,
        contact: addressWithContact.contact,
        country: addressWithContact.address?.country,
      }),
      overrides: getContactFormOverrides({checkoutSettings, overridePhone: cashierMandatoryFields?.phone}),
    });

  const addressValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.ADDRESS,
      values: getAddressFormInitialState(checkoutSettings, addressWithContact.address),
      overrides: getAddressFormOverrides({checkoutSettings, overrides: cashierMandatoryFields}),
    });

  const vatValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.VAT_ID,
      values: getVatFormInitialState(addressWithContact.contact),
      overrides: {},
    });

  const shouldValidateVat =
    addressWithContact.address?.country && COUNTRIES_WITH_VAT.includes(addressWithContact.address?.country);

  return validateAllForms([contactValidator, addressValidator, ...(shouldValidateVat ? [vatValidator] : [])]);
};

export const isShippingDestinationValid: MemberDetailsValidator<CustomFieldModel | undefined> = async ({
  validate,
  addressWithContact,
  checkoutSettings,
  context: customField,
}) => {
  const contactValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.CONTACT,
      values: getContactFormInitialState({
        checkoutSettings,
        contact: addressWithContact.contact,
        country: addressWithContact.address?.country,
      }),
      overrides: getContactFormOverrides({checkoutSettings}),
    });

  const addressValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.ADDRESS,
      values: getAddressFormInitialState(checkoutSettings, addressWithContact.address),
      overrides: getAddressFormOverrides({checkoutSettings}),
    });

  const additionalInfoValidator = () =>
    validate({
      formId: FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
      values: getAdditionalInfoFormInitialState(customField),
      overrides: getAdditionalInfoFormOverrides({checkoutSettings}),
    });

  return validateAllForms([contactValidator, addressValidator, additionalInfoValidator]);
};

async function validateAllForms(formValidations: (() => Promise<{errors: any[]} | undefined> | undefined)[]) {
  const validationErrors = await Promise.all(formValidations.map((validateForm) => validateForm()));
  const hasAnyErrors = validationErrors.some(
    (validationRes) => validationRes?.errors?.length && validationRes?.errors?.length > 0
  );

  return !hasAnyErrors;
}
